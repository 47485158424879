<template>
  <iframe :src="url"></iframe>
</template>

<script>
import { XJS_URL } from "@/config";
export default {
  data() {
    return {
      url: `${XJS_URL}URLTable`,
    };
  },
};
</script>

<style lang="less" scoped>
iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
</style>